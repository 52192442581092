.page.auth {
    .register {
        &__footer {
            width: 100%;
            text-align: center;
            line-height: 1.3;
        }
    }
}

@media(max-width: 991px) {
    .app {
        &.register {
            height: auto;
            overflow: auto;
        }
    }
    .page.auth {
        flex-direction: column;
        padding: 30px 0 0 0;
        .register {
            &__footer {
                position: unset;
                font-size: 0.75rem;
            }
        }
    }
}
:root {
    --primary-color: #69bfdc;
}

#root, .app {
    height: 100%;
}

.page {
    &.auth {
        height: 100%;
        display: flex;
        align-items: center;
        > .ant-row {
            width: 100%;
        }
        .ant-btn-primary {
            width: 100%;
        }
        .login-form-forgot {
            text-align: right;
            width: 100%;
            display: inline-block;
        }
        .auth {
            &__logo {
                width: 30%;
                margin: 0 auto;
                display: block;
            }
        }
    }
    &.error {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__wrapper {
        overflow: auto;
        padding: 24px;
        min-height: 360px;
        height: calc(100% - 80px);
    }
}

@media(max-width: 991px) {
    .app {
        overflow: hidden;
    }
    .page {
        height: 100%;
        &__wrapper {
            &.chat {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .ant-table {
            overflow: auto;
            &-content > table {
                width: 890px;
            }
        }
    }
}
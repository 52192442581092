.page {
    &.dates {
        .ant-picker-range {
            width: 100%;
        }
        .ant-table-row {
            .ant-table-cell {
                &:last-child {
                    text-align: right;
                    .ant-btn {
                        margin: 0 0 0 10px;
                    }
                }
                .ant-form-item {
                    margin: 0;
                }
            }
        }
    }
}
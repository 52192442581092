.chat {
    .history {
        height: 605px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        .markdate {
            margin: 1rem 0 0 0;
            text-align: center;
            span {
                background: rgba(162, 206, 112, .5);
                padding: 3px 6px;
                border-radius: 20px;
            }
        }
        .message {
            display: flex;
            width: 100%;
            margin: 1rem 0 0 0;
            &.me {
                justify-content: flex-end;
                .body {
                    background-color: var(--primary-color);
                    &__status {
                        display: flex;
                        justify-content: flex-end;
                        font-size: 0.75rem;
                        color: rgba(0,0,0,.3);
                        position: absolute;
                        right: .2rem;
                        bottom: .25rem;
                        &.read {
                            color: white;
                        }
                        &.sended {
                            color: white;
                        }
                        &.error {
                            right: .4rem;
                            bottom: .3rem;
                            color: #ff4d4f;
                        }
                    }
                    &__attachments {
                        .attach {
                            color: var(--primary-color);
                        }
                    }
                    &__time {
                        right: 1.5rem;
                    }
                }
            }
            &.member {
                justify-content: flex-start;
                align-items: flex-end;
                .body {
                    background-color: rgba(0,0,0,.1);
                    &__attachments {
                        .attach {
                            color: #e5e5e5;
                        }
                    }
                }
            }
            &__avatar {
                margin: 0 1rem 0 0;
            }
            .body {
                max-width: 70%;
                min-width: 200px;
                display: flex;
                flex-direction: column;
                border-radius: 4px;
                color: rgba(0,0,0,.8);
                padding: .5rem;
                box-shadow: 0 1px .5px rgba(0,0,0,.13);
                position: relative;
                &__name {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    > span {
                        font-weight: 600;
                    }
                    .role {
                        font-weight: normal;
                        font-size: 0.75rem;
                        font-style: italic;
                        color: rgba(0,0,0,.3);
                    }
                }
                &__text {
                    display: inline-block;
                    width: 99%;
                    white-space: pre-line;
                }
                &__attachments {
                    .attach {
                        background: transparent;
                        outline: none;
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        border-radius: 2px;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.5rem;
                        line-height: 1;
                        padding: 2.5px 3px;
                        background-color: rgba(0,0,0,.5);
                        margin: .25rem .5rem .25rem 0;
                        &:last-child {
                            margin: .25rem 0 .25rem 0;
                        }
                    }
                }
                &__time {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 0.75rem;
                    color: rgba(0,0,0,.3);
                    position: absolute;
                    right: .5rem;
                    bottom: .2rem;
                }
            }
            
        }
    }
    .sendarea {
        border-top: 1px solid rgba(0,0,0,.1);
        margin: 1rem 0 0 0;
        padding: 1rem 0 .5rem 0;
        display: flex;
        flex-direction: column;
        height: 135px;
        &__main {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            textarea {
                border: 1px solid #e5e5e5;
                border-radius: 10px;
                padding: .5rem 1rem;
                height: 100%;
                resize: none;
            }
        }
        &__actions {
            display: flex;
            button {
                background: transparent;
                outline: none;
                cursor: pointer;
                border: none;
                color: var(--primary-color);
                font-size: 1.5rem;
                line-height: 1;
                padding: 2.5px 3px;
                margin: 0 0 0 .5rem;
                &.send {
                    font-size: 1.3rem;
                }
            }
        }
        &__attachments {
            display: flex;
            margin: .5rem 0 0 0;
            .attach {
                width: 40px;
                height: 40px;
                border-radius: 2px;
                border: 1px solid #e5e5e5;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin: 0 .5rem 0 0;
                &:last-child {
                    margin: 0;
                }
                > .anticon {
                    font-size: 1.3rem;
                    color: var(--primary-color);
                }
                .actions {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 2px;
                    transition: linear .3s;
                    &:hover {
                        background-color: rgba(0,0,0,.5);
                        .actions__remove {
                            opacity: 1;
                        }
                    }
                    .anticon {
                        color: white;
                        font-size: 1rem;
                    }
                    &__remove {
                        opacity: 0;
                        transition: linear .3s;
                        cursor: pointer;
                        padding: 0;
                        border: none;
                        background: transparent;
                        line-height: 1;
                        .anticon {
                            color: #ff4d4f;
                        }
                    }
                }
            }
        }
    }
    &__empty {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .anticon {
            font-size: 4rem;
            color: rgba(0,0,0,.2);
        }
        .ant-typography {
            margin: 1rem 0 0 0;
            color: rgba(0,0,0,.2);
        }
    }
    &__right {
        .header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0 0 7.5px 0;
            &__actions {
                button:last-child {
                    margin: 0 0 0 1rem;
                }
            }
            &__calendar {
                button:last-child {
                    margin: 0 0 0 .5rem;
                }
            }
        }
    }
}

@media(max-width: 991px) {
    .chat {
        &__right {
            .header {
                flex-direction: column;
                &__calendar {
                    display: flex;
                    justify-content: space-between;
                    .ant-picker-range {
                        max-width: 85%;
                    }
                    button:last-child {
                        margin: 0;
                    }
                }
                &__actions {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: .5rem;
                    button:last-child {
                        margin: 0 0 0 .5rem;
                    }
                }
            }
        }
    }
}
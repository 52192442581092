.page {
    &.users {
        .ant-picker-range {
            width: 100%;
        }
        .ant-table-row {
            .ant-table-cell {
                &:last-child {
                    text-align: right;
                    .ant-btn {
                        margin: 0 0 0 10px;
                    }
                }
            }
        }
        .users {
            &__actions {
                margin: 0 0 30px 0;
            }
        }
    }
}
.site-layout {
    &-background {
        background-color: white;
        justify-content: space-between;
    }
    .ant-layout-header {
        background-color: white;
        display: flex;
        align-items: center;
        padding: 0 16px;
        .ant-typography {
            margin: 0;
        }
    }
}

.ant-layout {
    height: 100vh;
    &-content {
        height: calc(100% - 64px);
    }
}

.logo {
    height: 32px;
    margin: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.header {
    &__right {
        display: flex;
        align-items: center;
        .ant-btn {
            margin: 0 0 0 10px;
            &:first-child {
                margin: 0;
            }
        }
    }
    &__notify {
        margin-right: .5rem !important;
        button {
            padding: 0;
            background: transparent;
            border: none;
            outline: none;
            &:hover {
                .anticon {
                    color: #69bfdc;
                }
            }
            .anticon {
                font-size: 1.5rem;
                cursor: pointer;
                transition: ease-in .2s;
            }
        }
    }
}

@media(max-width: 991px) {
    .app {
        &__main-title {
            font-size: 1.25rem !important;
            line-height: 1 !important;
        }
    }
    .page {
        .ant-divider-horizontal.ant-divider-with-text {
            margin-top: 0;
            &::before, &::after {
                top: 0;
            }
        }
    }
    .ant-layout {
        min-height: unset;
        height: 100vh;
    }
    .header {
        &__right {
            display: flex;
        }
    }
}
.page {
    &.chat {
        height: 100%;
        > .ant-row {
            height: 100%;
        }
    }
    .chat {
        .ant {
            &-row {
                height: 100%;
            }
        }
        &__left {
            border-right: 1px solid rgba(0,0,0,.1);
        }
        &__right {
            display: flex !important;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
    
    .ant-picker-cell .ant-picker-cell-inner {
        &.hasMessages:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            border: 1px solid #a2ce70;
            border-radius: 2px;
            content: '';
        }
    }
}

@media(max-width: 991px) {
    .page {
        &.chat {
            margin: 0 -16px;
        }
        .chat {
            &__left {
                padding: 0 !important;
                border: none;
            }
            &__right {
                height: 100%;
            }
        }
    }
}
.equipments {
    .ant-form-item {
        margin: 0;
    }
    .ant-table {
        margin: 30px 0;
    }
    .ant-table-row {
        .ant-table-cell:last-child {
            text-align: right;
            .ant-btn {
                margin: 0 0 0 10px;
            }
        }
    }
}
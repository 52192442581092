.page {
    &.dashboard {
        .dashboard {
            &__auth {
                margin-top: 1rem;
            }
            &__popular {
                margin-top: 1rem;
            }
            &__header {
                margin: .5rem 0 1rem 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

@media(max-width: 991px) {
    .page {
        &.dashboard {
            .ant-card-bordered {
                margin-bottom: 1rem;
            }
            .dashboard {
                &__header {
                    .ant-btn {
                        margin: 0 0 0 .5rem;
                    }
                }
            }
        }
    }
    
}
.chat {
    .conversation {
        background: transparent;
        width: 100%;
        border: none;
        border-radius: 0;
        display: flex;
        align-items: center;
        padding: .5rem;
        cursor: pointer;
        transition: linear .3s;
        &:hover, &.active {
            background-color: rgba(105, 191, 220, .2);
        }
        &__image {
            color: #f56a00;
            background-color: #fde3cf;
            width: 40px;
        }
        .info {
            width: calc(100% - 40px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 0 0 1rem;
            .header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                &__name {
                    font-weight: 600;
                    text-align: left;
                    line-height: 1.3;
                    max-width: 190px;
                }
                &__lasttime {
                    font-size: 0.75rem;
                    color: rgba(0,0,0,.5);
                }
            }
            .body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .message {
                    &__name {
                        transition: linear .3s;
                        color: #69bfdc;
                    }
                }
                &__new-badge {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 10px;
                    height: 10px;
                    background-color: #ff4d4f;
                    border-radius: 100%;
                }
            }
        }
    }
}

@media(max-width: 991px) {
    .chat {
        .conversation {
            padding: 0 .5rem;
            margin: 1rem 0 0 0;
            .info {
                .header {
                    &__name {
                        text-align: left;
                        line-height: 1.2;
                    }
                    &__lasttime {
                        display: none;
                    }
                }
            }
        }
    }
}